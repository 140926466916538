




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsPagination,
        LsDialog
    }
})
export default class LBGoodsPane extends Vue {
    @Prop() value: any // 列表数据
    @Prop() pager!: any // 含有分页信息的数据
    @Prop() type?: string // 含有分页信息的数据

    onDelete(value: any) {
        this.$emit('onDel', value)
    }
}
