






































































import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import {
    apiLiveGoodsLists,
    apiLiveGoodsDel
} from '@/api/application/live_broadcast'
import { PageMode } from '@/utils/type'
import GoodsPane from '@/components/live-broadcast/goods-pane.vue'
@Component({
    components: {
        GoodsPane
    }
})
export default class LiveBroadcastGoods extends Vue {
    // 分页查询
    pager: RequestPaging = new RequestPaging()

    form = {
        type: '1'
    }

    extend = {
        all: 0,
        wait: 0,
        ing: 0,
        end: 0
    }

    // 添加直播商品
    addLBGoods() {
        this.$router.push({
            path: '/live_broadcast/add_broadcast_goods',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 同步商品库
    synchronizationLBGoods() {
        // this.$set(this.pager, 'page', 1)
        this.getList()
    }

    // 删除
    onDelete(value: any) {
        apiLiveGoodsDel({
            goods_id: value.goods_id
        })
            .then(() => {
                this.getList()
                // this.$message.success("删除成功!");
            })
            .catch(() => {
                //this.$message.error("删除失败")
            })
    }

    // 获取列表
    getList() {
        // 分页请求
        this.pager
            .request({
                callback: apiLiveGoodsLists,
                params: {
                    ...this.form
                }
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载')
            })
    }

    created() {
        this.getList()
    }
}
